import React from "react";
import styled from "styled-components";
import { Color, rem, responsive } from "../../utils/style";

const Banner = styled.div.attrs({
  className: "col-12",
})`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Color.ritualLightBlue};
  margin-top: 16px;

  ${responsive.md`
    margin-top: 24px;
  `}

  p {
    color: ${Color.ritualBlue};
    font-size: ${rem(14)};
    line-height: ${rem(24)};
    font-weight: 300;
    letter-spacing: 0px;
    text-align: center;
    margin: 12px 8px;

    ${responsive.md`
      margin: 13px;
      font-size: ${rem(16)};
      line-height: ${rem(22)};
    `}
  }

  &.yellow {
    background: rgba(255, 214, 0, 0.56);

    p {
      color: ${Color.ritualBlue};
      font-weight: 500;
      font-size: ${rem(14)};
      line-height: ${rem(20)};

      ${responsive.md`
      font-size: ${rem(16)};
      line-height: ${rem(22)};
      `}
    }
  }
`;

export default class CartBanner extends React.Component {
  render() {
    const { bannerMessage, className } = this.props;

    return (
      <Banner className={className}>
        <p>{bannerMessage}</p>
      </Banner>
    );
  }
}
