import React from "react";
import styled from "styled-components";
import { throttle } from "underscore";

// Services
import { navigate } from "../../services/navigation";

// Utils
import { Color, responsive, rem } from "../../utils/style";
import { Icons } from "../../utils/svg";
import { trackCheckoutCTAClicked } from "../../utils/tracking/cart";

// Components
import Container from "../Container";
import AsyncRitualButton from "../global/AsyncRitualButton";
import Text from "../Text";

// Store
import { connect } from "react-redux";
import { reserveInput } from "../../store/apply-code/actions";

// Styled Elements
const NavContainer = styled.nav`
  height: 52px;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 999;
  background: ${Color.white};
  border-top: 4px solid ${Color.ritualBlue};
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);

  &.scrolled {
    border-top: none;
  }

  ${responsive.sm`
    height: 60px;
  `}

  ${responsive.md`
    height: 80px;
  `}
`;

const Nav = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  padding: 0 20px;
  ${responsive.sm`
    padding: 0;
  `}
`;

const TopCheckoutButtonContainer = styled.div`
  padding: 0;
  display: none;

  ${responsive.sm`
    display: block;
    width: 280px;
  `}

  ${responsive.md`
    width: 285px;
  `}
`;

const TopCheckoutButton = styled(AsyncRitualButton)`
  width: 100%;
  ${responsive.sm`
    height: 40px;

    span {
      font-size: 14px;
      line-height: 24px;
    }
  `}

  ${responsive.md`
    height: 50px;

    span {
      font-size: 18px;
      line-height: 28px;
    }
  `}
`;

const BottomNav = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  background: ${Color.white};
  z-index: 999;
  background-color: #ffffff;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;

  ${responsive.sm`
    display: none;
  `}
`;

const BottomCheckoutButtonContainer = styled.div`
  width: 100%;
  margin-top: 12px;
  margin-bottom: calc(12px + env(safe-area-inset-bottom));
  margin-left: 20px;
  margin-right: 20px;
`;

const BottomCheckoutButton = styled(AsyncRitualButton)`
  width: 100%;
  height: 40px;

  span {
    font-size: ${rem(14)};
    line-height: ${rem(24)};
  }
`;

const BackButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: none;
  border: none;
  padding: 0;

  [data-whatintent="mouse"] &:focus {
    outline: none;
  }

  svg {
    height: 12px;
  }

  p {
    color: ${Color.ritualBlue};
    margin-bottom: 0;
    margin-left: 16px;
    font-size: ${rem(14)};
    line-height: ${rem(24)};

    ${responsive.md`
      font-size: ${rem(18)};
      line-height: ${rem(28)};
    `}
  }
`;

export class CartNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolled: false,
    };

    this.handleBackClick = this.handleBackClick.bind(this);
    this.throttled = throttle(this.handleScroll.bind(this, window), 10);
    this._handleCheckoutCTAClicked = this._handleCheckoutCTAClicked.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      this.scrollHandler();
      this.handleScroll(window);
    }, 200);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.throttled);
  }

  scrollHandler() {
    window.addEventListener("scroll", this.throttled);
  }

  handleScroll(w) {
    let prevState = this.state.scrolled;
    if (typeof window !== "undefined" && window.scrollY >= 12 && !prevState) {
      this.setState({
        scrolled: true,
      });
    } else if (
      typeof window !== "undefined" &&
      window.scrollY < 12 &&
      prevState
    ) {
      this.setState({
        scrolled: false,
      });
    }
  }

  handleBackClick() {
    const { previousUrl } = this.props;
    let backUrl = "/";

    if (previousUrl && previousUrl !== "/cart") {
      if (previousUrl === "/build-a-bundle") {
        backUrl = "/shop-bundles";
      } else {
        backUrl = previousUrl;
      }
    }

    navigate(backUrl);
  }

  async _handleCheckoutCTAClicked() {
    await trackCheckoutCTAClicked();
    await this.props.dispatchReserveInput();
  }

  render() {
    const { scrolled } = this.state;
    const { checkoutUrl, isProcessing } = this.props;

    const navAreaClassNames = scrolled ? "scrolled " : "";

    return (
      <div>
        <NavContainer className={navAreaClassNames}>
          <Nav>
            <BackButton onClick={this.handleBackClick}>
              <Icons.ArrowRoundedLeft />
              <p>
                <Text
                  id="cart.nav.continue"
                  defaultMessage="Continue Shopping"
                />
              </p>
            </BackButton>
            <TopCheckoutButtonContainer>
              <TopCheckoutButton
                disabled={isProcessing}
                onClick={this._handleCheckoutCTAClicked}
                target="_self"
                href={checkoutUrl}
              >
                <Text
                  id="cart.nav.button-checkout"
                  defaultMessage="Proceed to Checkout"
                />
              </TopCheckoutButton>
            </TopCheckoutButtonContainer>
          </Nav>
        </NavContainer>
        <BottomNav>
          <BottomCheckoutButtonContainer>
            <BottomCheckoutButton
              disabled={isProcessing}
              target="_self"
              onClick={this._handleCheckoutCTAClicked}
              href={checkoutUrl}
            >
              <Text
                id="cart.nav.button-checkout"
                defaultMessage="Proceed to Checkout"
              />
            </BottomCheckoutButton>
          </BottomCheckoutButtonContainer>
        </BottomNav>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { navigation } = state;
  return {
    previousUrl: navigation.previousUrl,
  };
};

export default connect(mapStateToProps, {
  dispatchReserveInput: reserveInput,
})(CartNav);
