import React from "react";
import styled from "styled-components";

// Services
import { navigate } from "../../services/navigation";

// Utils
import { rem, Color, responsive, rgba, Font } from "../../utils/style";
import NameMap from "../../utils/nameMap";
import { getBundleCartLimit } from "../../utils/bundle";
import { getProductAttributes } from "../../utils/planToProduct";

// Redux
import { connect } from "react-redux";
import {
  removeProductFromCart,
  updateCartProductQuantity,
} from "../../store/cart/actions";
import planSelectors from "../../store/plan/selectors";

// Components
import Img from "gatsby-image";
import RemoveItemModal from "./RemoveItemModal";
import QuantityPicker from "../global/QuantityPicker";
import Currency from "../Currency";

// Styled Elements
const CartItemContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${Color.white};
  padding: 16px;

  &::before {
    content: "";
    position: absolute;
    right: 16px;
    bottom: 0;
    left: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.16);
  }

  ${responsive.md`
    padding: 24px;

    &::before {
      right: 24px;
      left: 24px;
    }
  `};

  ${p =>
    p.isLast &&
    `
      border-bottom: none;
      margin-bottom: 0;
      &::before {
        display: none;
      }
  `};
`;

const SectionWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: -7px;
`;

const ImageAndSectionContainer = styled.div`
  display: flex;
`;

// Remove a11y focus from image link since title link is immediately adjacent (duplicate)
const ImageWrapper = styled.a.attrs({
  tabIndex: -1,
  "aria-hidden": "true",
})`
  position: relative;
  width: 56px;
  height: 56px;
  margin-right: 12px;

  ${responsive.md`
    width: 120px;
    height: 120px;
    margin-right: 24px;
  `}
`;

const SubscriptionInfo = styled.p`
  color: ${rgba(Color.ritualBlue, 0.56)};
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0px;
  line-height: 22px;
  margin-bottom: 0;

  ${responsive.md`
    font-size: 14px;
    line-height: 24px;
  `}
`;

const RemoveButton = styled.button`
  background: transparent;
  border: none;
  border-bottom: 2px solid ${Color.ritualBlue};
  outline: none;
  padding: 0;
  color: ${Color.ritualBlue};
  font-weight: 500;
  font-size: ${rem(12)};
  line-height: ${rem(18)};

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }

  &:hover {
    opacity: 1;
  }

  ${responsive.md`
    font-size: ${rem(14)};
    line-height: ${rem(24)};
  `}
`;

const Price = styled.p`
  color: ${Color.ritualBlue};
  font-weight: 300;
  font-size: ${rem(12)};
  line-height: ${rem(18)};
  margin: 0;

  ${responsive.md`
    font-size: ${rem(14)};
    line-height: ${rem(24)};
  `};
`;

const ProductTitleLink = styled.a``;

const ProductTitle = styled.p`
  font-size: ${rem(14)};
  line-height: ${rem(24)};
  font-weight: 500;
  margin: 0 0 8px;

  em {
    ${Font.dutch};
  }

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
    margin-bottom: 16px;
  `}
`;

const Section = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0;
  padding: 0;

  p {
    margin: 0;
    padding: 0;
  }
`;

const LastSection = styled(Section)`
  margin-top: 12px;

  ${responsive.md`
    margin-top: 16px;
  `};

  ${responsive.lg`
    margin-top: 9px;
  `};
`;

export const CartItemComponent = class CartItem extends React.Component {
  constructor() {
    super();
    this.state = {
      isModalOpen: false,
    };

    this.handleDetailClick = this.handleDetailClick.bind(this);
  }

  renderImage(cartImage) {
    return (
      <ImageWrapper href="#" onClick={this.handleDetailClick}>
        {cartImage && (
          <Img
            fluid={cartImage.fluid}
            loading="eager"
            alt={cartImage.description}
            ariaLabel={cartImage.description}
            style={{
              position: "absolute",
              left: 0,
              top: 0,
              width: "100%",
              height: "100%",
              zIndex: "0",
              userSelect: "none",
              userDrag: "none",
              pointerEvents: "none",
              touchCallout: "none",
            }}
          />
        )}
      </ImageWrapper>
    );
  }

  renderRemove(isProcessing) {
    return (
      <RemoveButton
        disabled={isProcessing}
        onClick={this.toggleVisibility.bind(this)}
      >
        Remove
      </RemoveButton>
    );
  }

  renderQuantity() {
    const { cartProduct, cartQuantity, isProcessing } = this.props;
    return (
      <QuantityPicker
        itemId={cartProduct.id}
        itemQuantity={cartProduct.quantity}
        totalQuantity={cartQuantity}
        maxQuantity={getBundleCartLimit()}
        onQuantityChange={this.onQuantityChange.bind(this)}
        disabled={isProcessing}
      />
    );
  }

  onQuantityChange(e) {
    const { cartProduct } = this.props;
    const quantity = e.target.value;

    this.props.dispatchUpdateCartProductQuantity(cartProduct.planId, quantity);
  }

  handleDetailClick() {
    // navigate to pdp when clicking product detail (img or title)
    const { slug } = getProductAttributes(this.props.plan.id);
    navigate(`/products/${slug}`);
  }

  toggleVisibility(e) {
    e && e.preventDefault();
    const isOpen = !this.state.isModalOpen;
    this.setState({
      isModalOpen: !this.state.isModalOpen,
    });
  }

  removeItem(planId, e) {
    e.preventDefault();
    if (this.props.dispatchRemoveProductFromCart) {
      this.props.dispatchRemoveProductFromCart(planId, true);
    }
    this.setState({
      isModalOpen: false,
    });
  }

  render() {
    const { cartProduct, product, isProcessing, isLast, plan } = this.props;
    const { isModalOpen } = this.state;

    const { cartImage, name } = product;
    const { productPrice, planId } = cartProduct;

    const styledName = NameMap(name);
    const price = (productPrice / 100).toFixed(2);

    return (
      <>
        <CartItemContainer isLast={isLast}>
          <ImageAndSectionContainer>
            {this.renderImage(cartImage)}
            <SectionWrapper>
              <Section>
                <a href="#" onClick={this.handleDetailClick}>
                  <ProductTitle
                    dangerouslySetInnerHTML={{ __html: styledName.html }}
                  />
                </a>
              </Section>
              <Section>
                <SubscriptionInfo>{plan.variantDescription}</SubscriptionInfo>
                <Price><Currency value={price} /></Price>
              </Section>
              <Section>
                <SubscriptionInfo>{plan.variantContents}</SubscriptionInfo>
                <SubscriptionInfo>Monthly</SubscriptionInfo>
              </Section>
              <LastSection>
                {this.renderRemove(isProcessing)}
                {this.renderQuantity()}
              </LastSection>
            </SectionWrapper>
          </ImageAndSectionContainer>
        </CartItemContainer>

        <RemoveItemModal
          isOpen={isModalOpen}
          onRequestClose={this.toggleVisibility.bind(this)}
          onRemove={this.removeItem.bind(this, planId)}
        />
      </>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  return {
    plan: planSelectors.planForId(state, {
      id: ownProps.cartProduct.planId,
    }),
  };
};

export default connect(mapStateToProps, {
  dispatchRemoveProductFromCart: removeProductFromCart,
  dispatchUpdateCartProductQuantity: updateCartProductQuantity,
})(CartItemComponent);
