import React from "react";
import styled from "styled-components";

// Services
import intl from "../../services/intl";

// Utils
import { Color, rem, responsive } from "../../utils/style";
import { getDiscountData, getBundleCartLimit } from "../../utils/bundle";
import { getProductSkuForPlanId } from "../../utils/planToProduct";
import { Icons } from "../../utils/svg";
import { variation } from "../../utils/launchDarkly";

// Redux
import { connect } from "react-redux";
import userSelectors from "../../store/user/selectors";
import cartProductSelectors from "../../store/cart-product/selectors";
import promotionSelectors from "../../store/promotion/selectors";

// Components
import Container from "../Container";
import Row from "../Row";
import CartItem from "./CartItem";
import CartReceipt from "./Receipt";
import BarrierBusters from "./BarrierBusters";
import CartBanner from "./CartBanner";
import Upsell from "./Upsell";
import Text from "../Text";
import MagicLink from "../MagicLink";

// Styled Elements
const ProductContainer = styled(Container)`
  padding-top: 48px;

  ${responsive.sm`
    padding-top: 76px;
  `}
`;

const ItemWrapper = styled.div.attrs({
  className: "col-12 col-sm-7",
})`
  display: flex;
  flex-direction: column;
  padding: 0;

  ${responsive.sm`
    padding-right: 10px;
    margin-bottom: 32px;
  `};

  ${responsive.md`
    padding-right: 20px;
    margin-bottom: 56px;
  `};
`;

const ReceiptWrapper = styled.div.attrs({
  className: "col-12 col-sm-5 col-md-4 offset-md-1",
})`
  padding-left: 20px;
  padding-right: 20px;

  ${responsive.sm`
    padding-left: 10px;
    padding-right: 0;
  `};

  ${responsive.md`
    padding-left: 20px;
  `};
`;

const Headline = styled.div.attrs({
  role: "section",
  "aria-labelledby": "review-order-heading",
})`
  margin: 24px 0 16px;

  ${responsive.sm`
    margin: 24px 0 16px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-direction: row;
  `};

  ${responsive.md`
    margin-top: 40px;
    margin-bottom: 24px;
  `};

  h1 {
    font-weight: 500;
    font-size: ${rem(26)};
    line-height: ${rem(36)};
    letter-spacing: -0.3px;
    margin: 0;

    ${responsive.md`
      font-size: ${rem(34)};
      line-height: ${rem(40)};
      letter-spacing: -0.5px;
    `};
  }

  p {
    font-weight: 500;
    font-size: ${rem(14)};
    line-height: ${rem(20)};
    margin: 8px 0 0;
    color: rgba(20, 43, 111, 0.56);

    a {
      color: inherit;
      border-bottom: 1px solid currentColor;
    }

    ${responsive.md`
      font-size: ${rem(16)};
      line-height: ${rem(22)};
      margin: 0;
	  `};
  }
`;

const ProductsWrapper = styled.div`
  padding: 24px 16px;
  margin-left: 8px;
  margin-right: 8px;
  background-color: rgba(242, 241, 245, 0.56);

  ${responsive.sm`
    margin-left: 0;
    margin-right: 0;
  `};

  ${responsive.md`
    padding: 40px;
  `};
`;

const BundleOffer = styled.div`
  width: 100%;
  background-color: ${Color.white};
  padding: 16px 24px;
  margin: 16px 0 0;
  text-align: center;

  ${responsive.md`
    padding: 24px 32px;
    margin-top: 24px;
  `};

  p {
    color: ${Color.ritualBlue};
    font-size: ${rem(14)};
    line-height: ${rem(24)};
    font-weight: 500;
    margin: 0 0 8px;

    ${responsive.md`
      font-size: ${rem(18)};
      line-height: ${rem(28)};
      margin-bottom: 16px;
    `};

    &:last-child {
      font-size: ${rem(12)};
      line-height: ${rem(18)};
      font-weight: 300;
      margin-bottom: 0;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;

      ${responsive.md`
        font-size: ${rem(14)};
        line-height: ${rem(20)};
      `};

      svg {
        width: 14px;
        height: 11px;
        margin-right: 12px;

        g {
          fill: ${Color.ritualYellow};
        }
      }
    }
  }
`;

const PaddingWrapper = styled.div`
  padding-left: 20px;
  padding-right: 20px;

  ${responsive.sm`
    padding-left: 0;
    padding-right: 0;
  `};
`;

const HeadlineWrapper = styled(PaddingWrapper).attrs({
  className: "col-12 col-sm-7",
})`
  ${responsive.sm`
    padding-right: 10px;
  `};

  ${responsive.md`
    padding-right: 20px;
  `};
`;

export const ReviewOrderComponent = class ReviewOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newYearsPromoEnabled: false,
    };
  }

  componentDidMount() {
    const newYearsPromoEnabled = variation("new-years-promo");

    this.setState({
      newYearsPromoEnabled,
    });
  }

  renderCartProductsList(
    cartProducts,
    contentfulProducts,
    isProcessing,
    isAddingToSubscription,
    discountText,
  ) {
    const { hasBundlePromoEligibleSubscription, cartQuantity } = this.props;

    const displayBundleOffer =
      hasBundlePromoEligibleSubscription || cartQuantity > 1;

    const bundleOfferText = intl.t(
      "cart.review-order.bundle-offer",
      `Your bundle saves {amount} off your {isAdding, select,
        yes {next}
        no {first}
      } order`,
      { amount: discountText, isAdding: isAddingToSubscription ? "yes" : "no" },
    );

    return (
      <ProductsWrapper>
        {cartProducts.map((cartProduct, i) => {
          const isLast = i + 1 === cartProducts.length;
          return this.renderCartProduct(
            cartProduct,
            contentfulProducts,
            isProcessing,
            isLast,
            cartQuantity,
          );
        })}

        {displayBundleOffer && (
          <BundleOffer>
            <p>{bundleOfferText}</p>
            <p>
              <Icons.CheckRounded />{" "}
              <Text
                id="cart.review-order.bundle-offer-p"
                defaultMessage="Ships together to reduce packaging"
              />
            </p>
          </BundleOffer>
        )}
      </ProductsWrapper>
    );
  }

  renderCartProduct(
    cartProduct,
    contentfulProducts,
    isProcessing,
    isLast,
    cartQuantity,
  ) {
    const productSku = getProductSkuForPlanId(cartProduct.planId);
    const contentfulProduct = contentfulProducts.find(product => {
      return productSku === product.sku;
    });

    return (
      <CartItem
        key={cartProduct.key}
        cartProduct={cartProduct}
        product={contentfulProduct}
        isProcessing={isProcessing}
        isLast={isLast}
        cartQuantity={cartQuantity}
      />
    );
  }

  render() {
    const {
      cart,
      cartProducts,
      cartQuantity,
      cartProductTotal,
      contentfulProducts,
      shouldShowLimitBanner,
      shouldShowWipeoutBanner,
      checkoutUrl,
      isProcessing,
      isLoggedIn,
      activePromotion,
      hasActiveSubscription,
      hasBundlePromoEligibleSubscription,
    } = this.props;
    const { newYearsPromoEnabled } = this.state;

    const quantityLimit = getBundleCartLimit();

    const isAddingToSubscription =
      hasActiveSubscription && cartQuantity !== quantityLimit;

    const isBundlePromotion =
      activePromotion && activePromotion.type === "Bundle";

    const shouldShowBundleBanner =
      !newYearsPromoEnabled &&
      (hasBundlePromoEligibleSubscription || isBundlePromotion);

    const upsellCartAmount = 2;
    const shouldShowUpsell =
      !isAddingToSubscription && cartQuantity <= upsellCartAmount;

    const discountData = getDiscountData(cartProductTotal / 100);
    const discountText = discountData.amountOff
      ? intl.formatCurrency(discountData.amountOff)
      : `${discountData.percentOff}%`;

    return (
      <>
        <ProductContainer>
          <Row>
            <div className="col-12 p-sm-0">
              {newYearsPromoEnabled && (
                <CartBanner
                  className="yellow"
                  bannerMessage={intl.t(
                    "cart.review-order.banner-ny-promo",
                    `Nice! You unlocked the New Years Sale discount.`,
                  )}
                />
              )}
              {shouldShowBundleBanner && (
                <CartBanner
                  className="yellow"
                  bannerMessage={intl.t(
                    "cart.review-order.banner-bundle",
                    `Nice! You unlocked a {copy} Bundle Discount.`,
                    { copy: discountText },
                  )}
                />
              )}
              {shouldShowLimitBanner && (
                <CartBanner
                  bannerMessage={intl.t(
                    "cart.review-order.banner-limit",
                    `You can only have {limit} items in your bag at a time—remove one to add another.`,
                    { limit: quantityLimit },
                  )}
                />
              )}
              {shouldShowWipeoutBanner && (
                <CartBanner
                  bannerMessage={intl.t(
                    "cart.review-order.banner-wipeout",
                    `You can only have {limit} items in your bag at a time—we’ve replaced your previous items with the new ones added.`,
                    { limit: quantityLimit },
                  )}
                />
              )}
            </div>
            <HeadlineWrapper>
              <Headline>
                <h1 id="review-order-heading">
                  {isAddingToSubscription
                    ? intl.t(
                        "cart.review-order.heading-is-adding",
                        "Adding to your subscription",
                      )
                    : intl.t("cart.review-order.heading", "Review order")}
                </h1>
                {!isLoggedIn && (
                  <p>
                    <Text
                      id="cart.review-order.not-logged"
                      defaultMessage="Already have an account?"
                    />{" "}
                    <MagicLink to="/login" title="Log In">
                      <Text id="general.link-login" defaultMessage="Log in" />
                    </MagicLink>
                  </p>
                )}
              </Headline>
            </HeadlineWrapper>
          </Row>

          <Row>
            <ItemWrapper>
              {this.renderCartProductsList(
                cartProducts,
                contentfulProducts,
                isProcessing,
                isAddingToSubscription,
                discountText,
              )}
              {shouldShowUpsell && (
                <PaddingWrapper>
                  <Upsell
                    contentfulProducts={contentfulProducts}
                    cartQuantity={cartQuantity}
                    isProcessing={isProcessing}
                    upsellSkus={cart.productRecommendations}
                    discountData={discountData}
                    newYearsPromoEnabled={newYearsPromoEnabled}
                  />
                </PaddingWrapper>
              )}
              <PaddingWrapper className="d-block d-sm-none">
                <BarrierBusters />
              </PaddingWrapper>
            </ItemWrapper>
            <ReceiptWrapper>
              <CartReceipt
                cart={cart}
                cartProducts={cartProducts}
                contentfulProducts={contentfulProducts}
                checkoutUrl={checkoutUrl}
                isProcessing={isProcessing}
                isLoggedIn={isLoggedIn}
                isAddingToSubscription={isAddingToSubscription}
                hasBundlePromoEligibleSubscription={
                  hasBundlePromoEligibleSubscription
                }
              />
              <div className="d-none d-sm-block">
                <BarrierBusters />
              </div>
            </ReceiptWrapper>
          </Row>
        </ProductContainer>
      </>
    );
  }
};

const mapStateToProps = state => {
  return {
    isLoggedIn: userSelectors.isLoggedIn(state),
    cartQuantity: cartProductSelectors.activeCartProductQuantity(state),
    cartProductTotal: cartProductSelectors.activeCartProductTotal(state),
    activePromotion: promotionSelectors.activePromotion(state),
  };
};

export default connect(mapStateToProps, {})(ReviewOrderComponent);
