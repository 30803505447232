import React from "react";
import { createGlobalStyle } from "styled-components";
import { Redirect } from "@reach/router";
import intl from "../../services/intl";

const HideNavAndFooter = createGlobalStyle`
  #navigation {
    display: none;
  }

  #footer {
    display: none;
  }
`;

export default class LoadingWrapper extends React.Component {
  constructor() {
    super();
    this.hasLoaded = false;
  }

  render() {
    const {
      children,
      isLoading,
      shouldRedirectOnLoad,
      redirectPath,
    } = this.props;

    if (isLoading) {
      return (
        <>
          <HideNavAndFooter />
          <div className="loading" />
        </>
      );
    }

    // If we're not currently loading, check if the page was ever loaded (if the
    // the wrapper's contents have been rendered). If so, we should not
    // redirect, as the redirect should occur before the page is rendered.
    if (!this.hasLoaded && shouldRedirectOnLoad) {
      return (
        <>
          <HideNavAndFooter />
          <Redirect noThrow to={intl.localizePath(redirectPath || "/")} />;
        </>
      );
    }

    // If isLoading is false and we haven't redirected, consider the page
    // loaded.
    this.hasLoaded = true;

    return <>{children}</>;
  }
}
