import React from "react";
import styled from "styled-components";
import { Color, responsive, rgba } from "../../utils/style";
import { Icons, PaymentIcons } from "../../utils/svg";
import Text from "../Text";

const PaymentsContainer = styled.div`
  margin-top: 16px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${responsive.lg`
    margin-top: 40px;
  `};

  p {
    color: ${rgba(Color.ritualBlue, 0.56)};
    font-size: 10px;
    font-weight: 500;
    letter-spacing: 0.67px;
    line-height: 18px;
    text-align: center;
  }

  p.pay-by {
    margin-bottom: 8px;

    ${responsive.lg`
      margin-bottom: 0px;
      width: 50px;
      text-align: right;
      margin-right: 16px;
    `}
  }
`;

const PayByContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  order: 2;

  ${responsive.lg`
    order: 2;
    flex-direction: row;
    width: calc(100% + 80px);
    max-width: 340px;
    margin: auto;
    margin-left: -30px;
    align-items: baseline;
  `}

  ${responsive.lg`
    margin: auto;
  `}
`;

const IconsContainer = styled.div`
  width: 100%;
  max-width: 405px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  div {
    width: calc((100% / 7) - 3px);

    ${responsive.lg`
      width: calc((100% / 7) - 4px);
    `}

    svg {
      width: 100%;
    }
  }
`;

const SecurePayments = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  order: 1;
  margin-bottom: 24px;

  ${responsive.lg`
    margin-bottom: 40px;
    order: 1;

    &:first-of-type {
      margin-top: -24px;
    }
  `}

  span {
    width: 7px;
    height: auto;
    position: relative;
    margin-right: 5px;

    svg {
      width: 7px;
      height: auto;
      position: absolute;
      top: 0;
      left: 0;
    }

    ${responsive.md`
      width: 10px;

      svg {
        width: 10px;
        height: 12px;
      }
    `};

    ${responsive.lg`
      margin-right: 10px;
    `}
  }

  p {
    color: ${Color.ritualBlue};
    font-size: 10px;
    font-weight: 300;
    letter-spacing: 0px;
    line-height: 12px;
    text-align: center;
    margin-bottom: 0px;

    ${responsive.lg`
      font-size: 12px;
    `}
  }
`;

export default class PaymentsSection extends React.Component {
  render() {
    return (
      <PaymentsContainer>
        <SecurePayments>
          <span>
            <Icons.Lock />
          </span>
          <p>
            <Text
              id="cart.receipt-payments.note"
              defaultMessage="Payments are processed securely."
            />
          </p>
        </SecurePayments>

        <PayByContainer>
          <p className="pay-by">
            <Text id="cart.receipt-payments.pay-by" defaultMessage="PAY BY" />
          </p>

          <IconsContainer>
            <div>
              <PaymentIcons.ApplePay />
            </div>
            <div>
              <PaymentIcons.GooglePay />
            </div>
            <div>
              <PaymentIcons.PayPal />
            </div>
            <div>
              <PaymentIcons.Visa />
            </div>
            <div>
              <PaymentIcons.Amex />
            </div>
            <div>
              <PaymentIcons.Discover />
            </div>
            <div>
              <PaymentIcons.MasterCard />
            </div>
          </IconsContainer>
        </PayByContainer>
      </PaymentsContainer>
    );
  }
}
