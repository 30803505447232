import React from "react";
import styled from "styled-components";

// Components
import AsyncRitualButton from "../global/AsyncRitualButton";
import PaymentsSection from "./ReceiptPayments";
import PromoCode from "./PromoCode";
import Text from "../Text";

// Services
import intl from "../../services/intl";

// Utils
import { rem, Color, responsive, rgba } from "../../utils/style";
import { trackCheckoutCTAClicked } from "../../utils/tracking/cart";

// Store
import { connect } from "react-redux";
import { reserveInput } from "../../store/apply-code/actions";
import couponSelectors from "../../store/coupon/selectors";
import promotionSelectors from "../../store/promotion/selectors";
import cartProductSelectors from "../../store/cart-product/selectors";

const RecieptContainer = styled.div.attrs({
  role: "section",
  "aria-labelledby": "receipt-header",
})`
  /* Height of bottom nav + 32 spacing */
  margin-bottom: calc(65px + 32px);

  ${responsive.sm`
    margin-bottom: 24px;
  `}

  ${responsive.md`
    margin-bottom: 32px;
  `}
`;

const Rule = styled.div`
  width: 100%;
  background: #000000;
  opacity: 0.16;
  border-radius: 0px;
  height: 1px;
  margin: 16px 0;

  ${responsive.md`
    margin-bottom: 24px;
  `};
`;

const TopRule = styled(Rule)`
  opacity: 1;
  background: ${Color.ritualBlue};
  height: 2px;

  ${responsive.sm`
    height: 3px;
  `};
`;

const SummarySection = styled.div``;

const SummaryTitle = styled.h2`
  color: ${Color.ritualBlue};
  font-size: ${rem(16)};
  line-height: ${rem(26)};
  font-weight: 500;
  margin: 0;

  ${responsive.md`
    font-size: ${rem(20)};
    line-height: ${rem(30)};
  `}
`;

const SummaryDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const SummaryItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 4px;

  ${responsive.md`
    margin-bottom: 8px;
  `};

  &:last-of-type {
    margin-bottom: 0;
  }

  p {
    color: ${p => (p.green ? Color.ritualGreen : Color.ritualBlue)};
    font-size: ${rem(14)};
    line-height: ${rem(24)};
    font-weight: 500;
    margin-bottom: 0;

    ${responsive.md`
      font-size: ${rem(18)};
      line-height: ${rem(28)};
    `}

    em {
      font-style: normal;
      font-weight: 300;
    }
  }

  p:last-of-type {
    color: ${p => (p.green ? Color.ritualGreen : rgba(Color.ritualBlue, 0.56))};
    font-weight: 300;
    text-align: right;
  }
`;

const TotalSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;

const EstimatedTotal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 24px;
    margin-bottom: 0;

    ${responsive.md`
      font-size: ${rem(18)};
      line-height: 28px;
    `}
  }

  p:last-of-type {
    color: ${rgba(Color.ritualBlue, 0.56)};
    font-weight: 300;
    text-align: right;
  }
`;

const CheckoutButtonSection = styled.div`
  display: none;

  ${responsive.sm`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  `}

  a {
    margin-top: 8px;
    width: 100%;
    max-width: 285px;

    ${responsive.md`
      margin-top: 16px;
      max-width: 100%;
    `};

    span {
      font-size: 18px;
      line-height: 28px;
    }
  }
`;

const PromoCodeWrapper = styled.div`
  margin-top: 32px;
  ${responsive.md`
    margin-top: 40px;
  `}
`;

const MessageContainer = styled.div`
  margin-top: 24px;

  p {
    margin: 0;
    color: ${Color.ritualGreen};
    font-weight: 500;

    margin-top: 8px;
    font-size: ${rem(12)};
    line-height: ${rem(20)};

    ${responsive.md`
      margin-top: 16px;
      font-size: ${rem(14)};
    `}

    &:first-of-type {
      margin-top: 0;
    }
  }
`;

export class CartReceipt extends React.Component {
  constructor() {
    super();
    this.shouldApplyPendingCode = false;
    this.handleCheckoutCTAClicked = this.handleCheckoutCTAClicked.bind(this);
  }

  getProductSummary(summary, quantity) {
    if (quantity === 1) {
      return summary;
    } else {
      return (
        <>
          {summary} <em>({quantity})</em>
        </>
      );
    }
  }

  getDeduction(price, amountOff, percentOff) {
    if (amountOff) {
      return amountOff;
    } else if (percentOff) {
      return price * (percentOff / 100);
    }
    return 0;
  }

  getCouponDurationMessage(coupon) {
    if (!coupon) return "";

    const { duration, durationInMonths } = coupon;

    if (duration === "repeating") {
      return intl.t(
        "cart.receipt.valid-for",
        `Coupon valid only for {duration} months.`,
        { duration: durationInMonths },
      );
    } else if (duration === "once") {
      return intl.t(
        "cart.receipt.code-applies-month-once",
        "Your promo code applies to this month.",
      );
    }
    return "";
  }

  getPromotionMessage(
    activePromotion,
    isAddingToSubscription,
    hasBundlePromoEligibleSubscription,
  ) {
    if (activePromotion) {
      const cart = this.props.cart;
      const prediscountTotal = this.formatCurrency(
        cart.subtotal + cart.discountAmount,
      );
      let message = intl.t(
        "cart.receipt.promo-applies-month",
        `Nice! Your {name} applies to this month`,
        { name: activePromotion.displayName },
      );
      if (!isAddingToSubscription) {
        message += intl.t(
          "cart.receipt.promo-adding",
          `. Future monthly totals will be {total} plus tax`,
          { total: prediscountTotal },
        );
      }
      return message;
    } else if (hasBundlePromoEligibleSubscription) {
      return intl.t(
        "cart.receipt.bundle-sub-applies-next",
        "A Bundle Discount will be applied in the next step",
      );
    }

    return null;
  }

  getCreditRemainingMessage(creditRemaining, giftCardAmountUsed) {
    if (!creditRemaining) return "";

    const credit = this.formatCurrency(creditRemaining);

    if (giftCardAmountUsed > 0) {
      return intl.t(
        "cart.receipt.credit-used",
        `Your gift card has been applied and your credit balance after this transaction is {credit}.`,
        { credit },
      );
    } else {
      return intl.t(
        "cart.receipt.credit-balance",
        `Your credit balance after this transaction is {credit}.`,
        { credit },
      );
    }
  }

  formatCurrency(amountInCents, deduction = false, isProcessing = false) {
    if (isProcessing) return <>&mdash;</>;
    const amountInDollars = (amountInCents / 100) * (deduction ? -1 : 1);
    return intl.formatCurrency(amountInDollars);
  }

  async handleCheckoutCTAClicked() {
    await trackCheckoutCTAClicked();
    await this.props.dispatchReserveInput();
  }

  render() {
    let {
      cart,
      activeCoupon,
      activePromotion,
      checkoutUrl,
      isProcessing,
      isLoggedIn,
      isAddingToSubscription,
      hasBundlePromoEligibleSubscription,
      activeCartProductQuantity,
    } = this.props;

    const couponDurationMessage = this.getCouponDurationMessage(activeCoupon);
    const promotionMessage = this.getPromotionMessage(
      activePromotion,
      isAddingToSubscription,
      hasBundlePromoEligibleSubscription,
    );
    const creditRemainingMessage = this.getCreditRemainingMessage(
      cart.creditRemaining,
    );
    const showMessage =
      couponDurationMessage ||
      (promotionMessage && !activeCoupon) ||
      creditRemainingMessage;

    return (
      <>
        <RecieptContainer>
          <SummarySection>
            <SummaryTitle id="receipt-header">
              <Text id="cart.receipt.summary-title" defaultMessage="Summary" />
            </SummaryTitle>
            <TopRule />

            <SummaryDetails>
              <SummaryItem>
                <p>
                  <Text
                    id="cart.receipt.product-total"
                    defaultMessage="Product Total"
                  />{" "}
                  ({activeCartProductQuantity})
                </p>
                <p>
                  {this.formatCurrency(
                    cart.prediscountTotal,
                    false /* deduction */,
                    isProcessing,
                  )}
                </p>
              </SummaryItem>

              {activeCoupon && (
                <SummaryItem green={true}>
                  <p>
                    <Text
                      id="cart.receipt.promo-label"
                      defaultMessage="Promo:"
                    />{" "}
                    <em>{activeCoupon.code}</em>
                  </p>
                  <p>
                    {this.formatCurrency(
                      cart.discountAmount,
                      true /* deduction */,
                      isProcessing,
                    )}
                  </p>
                </SummaryItem>
              )}
              {activePromotion && (
                <SummaryItem green={true}>
                  <p>{activePromotion.displayName}</p>
                  <p>
                    {this.formatCurrency(
                      cart.discountAmount,
                      true /* deduction */,
                      isProcessing,
                    )}
                  </p>
                </SummaryItem>
              )}

              <SummaryItem>
                <p>
                  <Text
                    id="cart.receipt.free-shipping-1"
                    defaultMessage="FREE Shipping"
                  />
                </p>
                <p>
                  <Text
                    id="cart.receipt.free-shipping-2"
                    defaultMessage="Always"
                  />
                </p>
              </SummaryItem>

              <SummaryItem>
                <p>
                  <Text id="cart.receipt.free-tax-1" defaultMessage="Tax" />
                  <em>
                    <Text
                      id="cart.receipt.free-tax-2"
                      defaultMessage="(calculated in checkout)"
                    />
                  </em>
                </p>
                <p>&mdash;</p>
              </SummaryItem>
            </SummaryDetails>
          </SummarySection>

          <Rule />

          {!!cart.giftCardAmountUsed && (
            <>
              <SummaryItem green={true}>
                <p>
                  <Text
                    id="cart.receipt.gift-card"
                    defaultMessage="Gift Card"
                  />
                </p>
                <p>
                  {this.formatCurrency(
                    cart.giftCardAmountUsed,
                    false /* deduction */,
                    isProcessing,
                  )}
                </p>
              </SummaryItem>
            </>
          )}

          {!!cart.creditsUsed && (
            <>
              <SummaryItem green={true}>
                <p>
                  <Text
                    id="cart.receipt.account-credit"
                    defaultMessage="Account Credit"
                  />
                </p>
                <p>
                  {this.formatCurrency(
                    cart.creditsUsed,
                    false /* deduction */,
                    isProcessing,
                  )}
                </p>
              </SummaryItem>
            </>
          )}

          <TotalSection>
            <EstimatedTotal>
              <p>
                <Text
                  id="cart.receipt.total-estimated"
                  defaultMessage="Estimated"
                />{" "}
                {isLoggedIn ? (
                  <Text
                    id="cart.receipt.total-logged-in"
                    defaultMessage="Subtotal"
                  />
                ) : (
                  <Text
                    id="cart.receipt.total-logged-out"
                    defaultMessage="Total"
                  />
                )}
              </p>
              <p>
                {this.formatCurrency(
                  cart.total,
                  false /* deduction */,
                  isProcessing,
                )}
              </p>
            </EstimatedTotal>

            {showMessage && (
              <MessageContainer>
                {couponDurationMessage && <p>{couponDurationMessage}</p>}
                {promotionMessage && <p>{promotionMessage}</p>}
                {creditRemainingMessage && <p>{creditRemainingMessage}</p>}
              </MessageContainer>
            )}

            {!isAddingToSubscription && (
              <PromoCodeWrapper>
                <PromoCode isProcessing={isProcessing} />
              </PromoCodeWrapper>
            )}
          </TotalSection>

          <CheckoutButtonSection>
            <AsyncRitualButton
              className={isProcessing ? "disabled" : ""}
              onClick={this.handleCheckoutCTAClicked}
              href={checkoutUrl}
              target="_self"
            >
              <Text
                id="cart.nav.button-checkout"
                defaultMessage="Proceed to Checkout"
              />
            </AsyncRitualButton>
          </CheckoutButtonSection>

          <PaymentsSection />
        </RecieptContainer>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    activeCoupon: couponSelectors.activeCoupon(state),
    activePromotion: promotionSelectors.activePromotion(state),
    activeCartProductQuantity: cartProductSelectors.activeCartProductQuantity(
      state,
    ),
  };
};

export default connect(mapStateToProps, {
  dispatchReserveInput: reserveInput,
})(CartReceipt);
