import React from "react";

// Page Component
import EmptyCart from "./EmptyCart";
import ReviewOrder from "./ReviewOrder";
import CartNav from "./CartNav";

// Utils
import { trackCartViewed } from "../../utils/tracking/cart";
import { getCheckoutUrlWithoutPlan } from "../../utils/urlBuilder";

// Store
import { connect } from "react-redux";
import {
  updateReplaceBanner,
  updateLimitBanner,
  updateWipeoutBanner,
} from "../../store/cart/actions";
import cartSelectors from "../../store/cart/selectors";
import cartProductSelectors from "../../store/cart-product/selectors";
import userSelectors from "../../store/user/selectors";
import subscriptionSelectors from "../../store/subscription/selectors";

const classPage = class Cart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cartViewedEventFired: false,
    };
  }

  componentDidMount() {
    this.props.updatePageData({
      label: "Cart",
    });

    this.props.updateLayoutState({
      navColor: "#FFFFFF",
      banner: false,
    });

    this._cartViewed();
  }

  componentWillUnmount() {
    const {
      dispatchUpdateReplaceBanner,
      dispatchUpdateLimitBanner,
      dispatchUpdateWipeoutBanner,
    } = this.props;
    dispatchUpdateReplaceBanner(false /* shouldDisplay */);
    dispatchUpdateLimitBanner(false);
    dispatchUpdateWipeoutBanner(false);
  }

  componentDidUpdate() {
    this._cartViewed();
  }

  _cartViewed() {
    const { activeCart, isProcessing } = this.props;
    if (activeCart && !isProcessing && !this.state.cartViewedEventFired) {
      trackCartViewed();
      this.setState({
        cartViewedEventFired: true,
      });
    }
  }

  render() {
    const checkoutUrl = getCheckoutUrlWithoutPlan();

    const {
      activeCart,
      activeCartProducts,
      contentfulProducts,
      shouldShowLimitBanner,
      shouldShowWipeoutBanner,
      isProcessing,
      isLoggedIn,
      hasActiveSubscription,
      hasBundlePromoEligibleSubscription,
    } = this.props;

    if (!activeCartProducts.length) {
      return (
        <EmptyCart
          hasActiveSubscription={hasActiveSubscription}
          hasBundlePromoEligibleSubscription={
            hasBundlePromoEligibleSubscription
          }
        />
      );
    }

    return (
      <>
        <CartNav checkoutUrl={checkoutUrl} isProcessing={isProcessing} />
        <ReviewOrder
          cart={activeCart}
          cartProducts={activeCartProducts}
          contentfulProducts={contentfulProducts}
          shouldShowLimitBanner={shouldShowLimitBanner}
          shouldShowWipeoutBanner={shouldShowWipeoutBanner}
          checkoutUrl={checkoutUrl}
          isProcessing={isProcessing}
          isLoggedIn={isLoggedIn}
          hasActiveSubscription={hasActiveSubscription}
          hasBundlePromoEligibleSubscription={
            hasBundlePromoEligibleSubscription
          }
        />
      </>
    );
  }
};

const mapStateToProps = state => {
  return {
    activeCart: cartSelectors.activeCart(state),
    activeCartProducts: cartProductSelectors.sortedActiveCartProducts(state),
    isProcessing: cartSelectors.isProcessing(state),
    shouldShowLimitBanner: cartSelectors.showLimitBanner(state),
    shouldShowWipeoutBanner: cartSelectors.showWipeoutBanner(state),
    isLoggedIn: userSelectors.isLoggedIn(state),
    hasActiveSubscription: subscriptionSelectors.hasActiveSubscription(state),
    hasBundlePromoEligibleSubscription: subscriptionSelectors.hasBundlePromoEligibleSubscription(
      state,
    ),
  };
};

export default connect(mapStateToProps, {
  dispatchUpdateReplaceBanner: updateReplaceBanner,
  dispatchUpdateLimitBanner: updateLimitBanner,
  dispatchUpdateWipeoutBanner: updateWipeoutBanner,
})(classPage);
