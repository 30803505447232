import { createSelector } from "reselect";

import cartSelectors from "../cart/selectors";

const _byId = state => state.promotion.byId;

const activePromotion = createSelector(
  _byId,
  cartSelectors.activeCart,
  (byId, activeCart) => {
    if (!activeCart) return null;
    return byId[activeCart.promotionId];
  },
);

export default {
  activePromotion,
};
