import React from "react";
import styled from "styled-components";
import { Color, rem, responsive, rgba } from "../../utils/style";
import Row from "../Row";
import { Icons } from "../../utils/svg";
import Text from "../Text";
import intl from "../../services/intl";

const BarrierBusterSection = styled.div`
  margin: 32px 0;

  ${responsive.md`
    margin: 60px 0 56px;
  `}

  h3 {
    font-size: ${rem(16)};
    font-weight: 500;
    letter-spacing: 0px;
    line-height: 26px;
    margin: 0;
    margin-bottom: 16px;

    ${responsive.md`
      font-size: ${rem(22)};
      letter-spacing: -0.2px;
      line-height: 32px;
      margin-bottom: 32px;
    `}
  }
`;

const Barriers = styled.div`
  display: flex;
  flex-direction: column;
`;

const BarrierContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: top;
  margin-bottom: 16px;

  ${responsive.md`
    margin-bottom: 40px;
  `}

  span {
    height: 24px;
    width: 24px;
    margin-right: 16px;
    margin-top: 5px;

    svg {
      height: 27px;
    }

    ${responsive.md`
      height: 32px;
      width: 32px;
      margin-right: 24px;
      margin-top: 12px;

      svg {
        height: 35px;
      }
    `}
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: left;

    p {
      margin: 0;
      font-size: ${rem(14)};
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 24px;

      ${responsive.md`
        font-size: ${rem(18)};
        line-height: 28px;
        margin-bottom: 4px;
      `}
    }

    p:nth-of-type(2) {
      color: ${rgba(Color.ritualBlue, 0.56)};
      font-weight: 300;

      ${responsive.md`
        font-size: ${rem(16)};
        line-height: 22px;
        margin-bottom: 0;
      `}
    }
  }
`;

export default class BarrierBusters extends React.Component {
  renderIcon(iconName) {
    switch (iconName) {
      case "Cancellation":
        return (
          <span>
            <Icons.Cancellation />
          </span>
        );
      case "Guarantee":
        return (
          <span>
            <Icons.Guarantee />
          </span>
        );
      case "Freeshipping":
        return (
          <span>
            <Icons.Freeshipping />
          </span>
        );
      default:
        return null;
    }
  }

  render() {
    const barriers = intl.unformattedTranslation(
      "cart.barrier-busting.barriers",
    );

    return (
      <>
        <BarrierBusterSection>
          <h3>
            <Text
              id="cart.barrier-busting.title"
              defaultMessage="You’re in control of your Ritual."
            />
          </h3>

          <Barriers>
            {barriers.map(barrier => {
              return (
                <BarrierContainer key={barrier.name}>
                  {this.renderIcon(barrier.icon)}
                  <div>
                    <p>{barrier.heading}</p>
                    <p>{barrier.subheading}</p>
                  </div>
                </BarrierContainer>
              );
            })}
          </Barriers>
        </BarrierBusterSection>
      </>
    );
  }
}
