import React from "react";
import styled from "styled-components";
import Img from "gatsby-image";

// Services
import { navigate } from "../services/navigation";

// Components
import DiscountButton from "./bundle/DiscountButton";

// Utils
import { Color, Font, rem, responsive } from "../utils/style";
import NameMap from "../utils/nameMap";
import {
  getPlanForId,
  getContentfulProductForPlan,
  getProductAttributes,
} from "../utils/planToProduct";

// Store
import { connect } from "react-redux";
import { addProductToCart } from "../store/cart/actions";

const LinkContainer = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  appearance: none;
  background: none;
  border: none;

  background-color: rgba(242, 241, 245, 0.56);
  color: ${Color.ritualBlue};

  width: 100%;
  padding: 16px 16px 24px 16px;
  border-radius: 8px;

  [data-whatintent="mouse"] &:focus,
  [data-whatintent="touch"] &:focus {
    outline: none;
  }
`;

const ProductImageWrapper = styled.div`
  height: 84px;
  width: 84px;
`;

const ProductName = styled.h3`
  margin: 0;
  margin-bottom: 16px;
  font-weight: 500;
  letter-spacing: 0px;

  font-size: ${rem(14)};
  line-height: ${rem(24)};

  ${responsive.md`
    font-size: ${rem(16)};
    line-height: ${rem(26)};
  `}

  em {
    ${Font.dutch};
    font-style: italic;
  }
`;

const QuickAddButtonWarpper = styled.div`
  width: 160px;
`;

export class QuickAddCard extends React.Component {
  constructor(props) {
    super(props);

    this.plan = getPlanForId(props.planId);
    this.contentfulProduct = getContentfulProductForPlan(props.planId);
    this.productAttributes = getProductAttributes(props.planId);
    this.handleCtaClick = this.handleCtaClick.bind(this);
    this.handleCardClick = this.handleCardClick.bind(this);
  }

  handleCtaClick() {
    const propertiesToTrack = this.props.location
      ? { location: this.props.location }
      : {};
    this.props.dispatchAddProductToCart(this.plan.id, propertiesToTrack);
    if (this.props.onClick) {
      this.props.onClick();
    }
  }

  handleCardClick() {
    // link to PDP when clicking on the card (not Cta)
    const { slug } = this.productAttributes;
    navigate(`/products/${slug}`);
  }

  render() {
    const { blueButton, disabled, showStrikethrough } = this.props;
    const image = this.contentfulProduct.alternateThumbnail;

    return (
      <LinkContainer onClick={this.handleCardClick}>
        <ProductImageWrapper>
          {image && (
            <Img
              fixed={image.fixed}
              fadeIn={true}
              alt={image.description}
              style={{
                width: "100%",
                height: "100%",
                userSelect: "none",
                userDrag: "none",
                pointerEvents: "none",
                touchCallout: "none",
              }}
            />
          )}
        </ProductImageWrapper>
        <ProductName
          dangerouslySetInnerHTML={{
            __html: NameMap(this.contentfulProduct.name).html,
          }}
        />
        <QuickAddButtonWarpper>
          <DiscountButton
            onClick={this.handleCtaClick}
            price={this.productAttributes.price}
            prefix="Add "
            className={`short ${blueButton ? "" : "primary-cta"}`}
            disabled={disabled}
            showStrikethrough={showStrikethrough}
          />
        </QuickAddButtonWarpper>
      </LinkContainer>
    );
  }
}

export default connect(null, {
  dispatchAddProductToCart: addProductToCart,
})(QuickAddCard);
