import React from "react";
import styled from "styled-components";
import { Color, Font, rem, responsive } from "../../utils/style";

// Services
import intl from "../../services/intl";

// Components
import ScrollableOverflow from "../ScrollableOverflow";
import QuickAddCard from "../QuickAddCard";
import BundleRibbon from "../bundle/Ribbon";
import Ribbon from "../global/Ribbon";
import Text from "../Text";

// Utils
import {
  getProductAttributes,
  getPlanIdForProductSku,
} from "../../utils/planToProduct";
import metrics from "../../utils/metrics";
import { trackUpsell } from "../../utils/tracking/cart";
import MagicLink from "../MagicLink";

// Styled Elements
const UpsellContainer = styled.div.attrs({
  role: "section",
  "aria-labelledby": "upsell-heading",
})`
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  margin-top: 32px;

  ${responsive.md`
    align-items: center;
    margin-top: 64px;
  `}
`;

const UpsellHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: flex-start;
  margin-bottom: 16px;

  ${responsive.md`
    align-items: center;
    margin-bottom: 32px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  `}
`;

const UpsellHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  h2 {
    margin: 0;
    margin-right: 16px;
    font-weight: 500;

    font-size: ${rem(16)};
    line-height: ${rem(26)};

    ${responsive.md`
      font-size: ${rem(20)};
      line-height: ${rem(30)};
    `}
  }
`;

const UpsellSubheading = styled.div`
  p {
    margin: 0;
    ${Font.dutch};
    font-size: ${rem(14)};
    line-height: ${rem(24)};
  }
`;

const UpsellContentWrapper = styled.div`
  width: 100%;
  display: flex;

  margin-bottom: 16px;

  ${responsive.md`
    margin-bottom: 32px;
  `}
`;

const UpsellContent = styled.div`
  width: 100%;
  display: flex;
`;

const QuickAddCardWrapper = styled.div`
  flex: 1 1 auto;
  min-width: 0;

  margin: 0 8px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }
`;

const ShopAllLink = styled.div`
  p {
    margin: 0;
    ${Font.dutch};
    letter-spacing: 0px;
    font-size: ${rem(14)};
    line-height: ${rem(24)};

    ${responsive.md`
      font-size: ${rem(16)};
      line-height: ${rem(26)};
    `}
  }

  a {
    border-bottom: 1px solid ${Color.ritualBlue};
  }
`;

/**
 * @component Upsell - Renders a list of quick add cards
 *
 * @param {Array} contentfulProducts - Array of contentful product objects
 * @param {Number} cartQuantity -  Quantity of products in cart
 * @param {Boolean} isProcessing - Determines if cart is processing a change
 * @param {Array} upsellSkus - Array of SKU strings to display
 */
export default class Upsell extends React.Component {
  componentDidMount() {
    this._trackUpsell(this.props.upsellSkus);
  }

  componentDidUpdate(prevProps) {
    if (this.props.upsellSkus !== prevProps.upsellSkus) {
      this._trackUpsell(this.props.upsellSkus);
    }
  }

  _trackUpsell(skus) {
    if (skus && skus.length > 0) trackUpsell(this.props.upsellSkus);
  }

  handleCTAClick(productName) {
    metrics.track("CTA Clicked", {
      location: "Cart Upsell",
      title: productName,
    });
  }

  handleShopAllClick() {
    metrics.track("CTA Clicked", {
      location: "Cart Upsell",
      title: "Shop All",
    });
  }

  _contentfulProductStockStatus(sku) {
    const { contentfulProducts } = this.props;
    const product = contentfulProducts.filter(product => product.sku === sku);
    if (product.length > 0) {
      return product[0].stockStatus;
    }
    return null;
  }

  _getInStockSkus(skus) {
    const skuList = skus;

    // Only allow products that are in stock to be added to upsell
    return skuList.reduce((result, sku) => {
      const productStockStatus = this._contentfulProductStockStatus(sku);
      if (productStockStatus === "in stock") {
        result.push(sku);
      } else {
        console.warn(`${sku} is out of stock - removing from Upsell`);
      }
      return result;
    }, []);
  }

  render() {
    const {
      isProcessing,
      upsellSkus,
      cartQuantity,
      discountData,
      newYearsPromoEnabled,
    } = this.props;

    const inStockSkus = this._getInStockSkus(upsellSkus);

    if (!inStockSkus || !inStockSkus.length) return null;

    const upsellCards = inStockSkus.map(sku => {
      const planId = getPlanIdForProductSku(sku);
      const attributes = getProductAttributes(planId);
      return (
        <QuickAddCardWrapper key={planId}>
          <QuickAddCard
            planId={planId}
            disabled={isProcessing}
            onClick={this.handleCTAClick.bind(this, attributes.name)}
            showStrikethrough={cartQuantity === 1 || newYearsPromoEnabled}
            blueButton={false}
          />
        </QuickAddCardWrapper>
      );
    });

    // The S and M breakpoint render one fewer card.
    const shortedUpsellCards = [...upsellCards];
    shortedUpsellCards.pop();

    let title = intl.t("cart.upsell.title", "Partnering Up Pays Off");
    if (cartQuantity > 1) {
      title = intl.t("cart.upsell.title-quantity", "Make It a Package Deal");
    }

    return (
      <UpsellContainer>
        <UpsellHeader>
          <UpsellHeading>
            <h2 id="upsell-heading">{title}</h2>
            {cartQuantity < 2 &&
              (newYearsPromoEnabled ? (
                <Ribbon short={true}>
                  <Text id="cart.upsell.ny-promo" defaultMessage="Save 21%" />
                </Ribbon>
              ) : (
                <BundleRibbon short={true} />
              ))}
          </UpsellHeading>
          <UpsellSubheading>
            <p>
              {intl.t(
                "cart.upsell.upsell-subheading",
                `Save {copy} on your first order when you bundle 2 or more products.`,
                { copy: discountData.sentenceCopy },
              )}
            </p>
          </UpsellSubheading>
        </UpsellHeader>

        {/* The upsell content should be scrollable on XS breakpoints */}
        <UpsellContentWrapper className="d-flex d-sm-none">
          <ScrollableOverflow
            centered={false}
            bleedLeft={true}
            bleedRight={true}
          >
            <UpsellContent>{upsellCards}</UpsellContent>
          </ScrollableOverflow>
        </UpsellContentWrapper>

        {/**
         * For S and M breakpoints, only two cards are renderd. We don't
         * render the DraggalbeOverflow component as the UpsellContent has a
         * dynamic width, and expands to fill its container.
         */}
        <UpsellContentWrapper className="d-none d-sm-flex d-lg-none">
          <UpsellContent>{shortedUpsellCards}</UpsellContent>
        </UpsellContentWrapper>

        {/**
         * For L breakpoints, all three upsell cards are renderd. We don't
         * render the DraggalbeOverflow component as the UpsellContent has a
         * dynamic width, and expands to fill its container
         */}
        <UpsellContentWrapper className="d-none d-lg-flex">
          <UpsellContent>{upsellCards}</UpsellContent>
        </UpsellContentWrapper>

        <ShopAllLink>
          <p>
            <Text
              id="cart.upsell.shop-copy"
              defaultMessage="Looking for something else?"
            />{" "}
            <MagicLink
              to="/shop-vitamins"
              title="Shop the full lineup"
              onClick={this.handleShopAllClick.bind(this)}
            >
              <Text id="cart.upsell.shop-all" defaultMessage="Shop All" />
            </MagicLink>
          </p>
        </ShopAllLink>
      </UpsellContainer>
    );
  }
}
